<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-EntitiesController"
        add-route-name="add-inpo"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-form-row>
            <b-col
              md="6"
              class="mb-1 mb-md-0"
            >
              <search
                placeholder="Name, Email, Phone"
                :search-query.sync="searchQuery"
                @refresh="refreshTable"
              />
            </b-col>

            <b-col md="6">
              <subclass-select
                :subclass.sync="subclass"
                :class-id="1"
              />
            </b-col>

            <b-col md="6">
              <donation-category-select :donation-category.sync="donationCategory" />
            </b-col>

            <b-col md="6">
              <country-select
                :country.sync="country"
                classification="Organization"
              />
            </b-col>
            <b-col md="6">
              <state-select
                :state.sync="state"
                :country="country"
                classification="Organization"
              />
            </b-col>
            <b-col md="6">
              <city-select
                :city.sync="city"
                :country="country"
                :state="state"
                classification="Organization"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <inpos-table
      ref="parentTable"
      v-bind="{
        fetchData:getInpos,
        isBusy,
        pagination
      }"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import AddResource from '@/common/components/common/Table/AddResource.vue'
import InposTable from '@/common/components/Inpos/InposTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import CountrySelect from '@/common/components/common/FormInputs/CountrySelect.vue'
import CitySelect from '@/common/components/common/FormInputs/CitySelect.vue'
import StateSelect from '@/common/components/common/FormInputs/StateSelect.vue'
import SubclassSelect from '@/common/components/common/FormInputs/SubclassSelect.vue'
import DonationCategorySelect from '@/common/components/common/FormInputs/DonationCategorySelect.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'InpoList',
  components: {
    SearchButton,
    AddResource,
    Pagination,
    InposTable,
    Search,
    CountrySelect,
    CitySelect,
    StateSelect,
    SubclassSelect,
    DonationCategorySelect,
  },
  data() {
    return {
      organizationTable: null,
      rows: [],
      isBusy: false,
      searchQuery: '',
      loading: true,
      items: [],
      country: '',
      state: '',
      city: '',
      subclass: [],
      donationCategory: '',
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getInpos() {
      const promise = this.$entities
        .get(`internalops/entity/list/${this.pagination.currentPage}/${this.pagination.perPage}`, {
          params: {
            search_str: this.searchQuery,
            type: 'Organization',
            country: this.country,
            city: this.city,
            state: this.state,
            subclassification: this.subclass,
            donationCategory: this.donationCategory,
          },
        })
      return promise.then(res => {
        this.pagination.totalRows = res.data.data.pagination.total

        const items = res.data.data.data
        return items || []
      }).finally(() => {
        this.loading = false
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
