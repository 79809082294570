<template>
  <b-dropdown-item
    v-if="item.status && $can('deactivate','global-EntitiesController')"
    @click="deactivateEntity(item)"
  >
    <feather-icon
      icon="ToggleRightIcon"
    />
    Deactivate
  </b-dropdown-item>

  <b-dropdown-item
    v-else-if="$can('activate','global-EntitiesController')"
    @click="activateEntity(item)"
  >
    <feather-icon
      icon="ToggleLeftIcon"
    />
    Activate
  </b-dropdown-item>
</template>
<script>
import changeEntityStatus from '@/common/compositions/common/changeEntityStatus'

export default {
  name: 'ToggleEntityStatus',
  props: {
    item: { type: Object, default: () => {} },
  },
  setup() {
    const { activateEntity, deactivateEntity } = changeEntityStatus()
    return { activateEntity, deactivateEntity }
  },
}
</script>
<style lang="">

</style>
