<template>
  <validation-provider
    #default="validationContext"
    :name="name"
    :rules="rules"
  >
    <b-form-group
      label-for="donation-category"
    >
      <template #label>
        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          {{ name }}
        </span>
      </template>

      <v-select
        id="donation-category"
        v-model="donationCategoryProxy"
        label="category_name"
        :reduce="donationCategory => donationCategory.id"
        :options="donationCategories"
        placeholder="Select Donation Category"
        :multiple="$attrs.multiple"
        :state="getValidationState(validationContext)"
        :disabled="disabled"
      />

      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'DonationCategorySelect',
  components: {
    vSelect,
  },
  props: {
    donationCategory: { type: [Array, Number, String], default: null },
    name: { type: String, default: '' },
    rules: { type: String, default: '' },
    type: { type: String, default: null },
    useType: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    entityId: { type: Number, default: null },
    status: { type: String, default: null },
  },
  data() {
    return {
      donationCategories: [],
    }
  },
  computed: {
    donationCategoryProxy: {
      get() {
        return this.donationCategory
      },
      set(value) {
        this.$emit('update:donationCategory', value)
      },
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  created() {
    this.getDonationCategories()
  },
  methods: {
    getDonationCategories() {
      this.$entities.get('internalops/donation-categories', {
        params: {
          paginated: false,
          typeFilter: this.type,
          useTypeFilter: this.useType,
          custom_entity_id: this.entityId,
          status: this.status,
        },
      }).then(res => {
        this.donationCategories = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
