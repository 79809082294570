import axiosInstances from '@/libs/axios-instances'
import handleAlerts from './handleAlerts'

export default function entityStatus() {
  const { successfulOperationAlert } = handleAlerts()
  const activateEntity = entity => {
    axiosInstances.entities.post('internalops/entity-activate', {
      entity_id: entity.id,
    }).then(() => {
      entity.status = !entity.status
      successfulOperationAlert('Entity is activated successfully')
    })
  }

  const deactivateEntity = entity => {
    axiosInstances.entities.post('internalops/entity-deactivate', {
      entity_id: entity.id,
    }).then(() => {
      entity.status = !entity.status
      successfulOperationAlert('Entity is deactivated successfully')
    })
  }

  return {
    activateEntity,
    deactivateEntity,
  }
}
